import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { getSubscriptions } from "actions/ais"
import { getApp } from "actions/apps"
import { MapFilterRecord } from "model/map/MapFilterRecordType"
import { clearMap, mapZoomEnd, resetProjectData, toggleAppLayer, toggleGroupLayers } from "../actions/globalActions"
import { GuidMap } from "../common/types/GuidMap"
import {
    mapSubToGeoJsonSource,
    mapSubToLayer,
    mapSubToLayout,
    mapSubToPaint,
    mapSubToZoomRange,
} from "../components/sidebar/ais/utils"
import { Basemap } from "../model/app/Basemap"
import { AisMapLayer } from "../model/map/AisMapLayer"
import { MapLayer } from "../model/map/MapLayer"
import { LayoutEntry, PaintEntry, ZoomRangeEntry } from "../model/map/PropertiesEntry"
import { Source } from "../model/map/Source"
import { setBoats, setSubVisibility } from "./ais"

type ClickPos = {
    lat: number
    lng: number
    x: number
    y: number
}

type ApplicationRestrictedView = {
    id: number
    mapBounds: number[][]
    maxZoom: number
    minZoom: number
    enabled: boolean
}

const defaultRestrictedView: ApplicationRestrictedView = {
    id: 0,
    mapBounds: [
        [-188.17, -75.01],
        [229.43, 84.28],
    ],
    maxZoom: 24,
    minZoom: 0,
    enabled: false,
}

type SliceState = {
    // Initting the map or changing the application shouldn't wipe these
    aisLayers: AisMapLayer[]
    aisLayouts: GuidMap<LayoutEntry>
    aisPaints: GuidMap<PaintEntry>
    aisSources: Source[]
    aisZoomRanges: GuidMap<ZoomRangeEntry>
    basemap: Basemap
    clickPos?: ClickPos
    filters: MapFilterRecord
    language: string

    layers: MapLayer[]
    layouts: GuidMap<LayoutEntry>
    loaded: boolean
    mapCoordinates: object
    measureDist: number
    measuring: boolean
    paints: GuidMap<PaintEntry>
    print: boolean
    printOutline: null
    restrictedView: ApplicationRestrictedView
    sources: Source[]
    zoom: number

    zoomRanges: GuidMap<ZoomRangeEntry>
    isAisDefaultVisible: boolean
}

const initialState: SliceState = {
    aisLayers: [],
    aisLayouts: {},
    aisPaints: {},
    aisSources: [],
    aisZoomRanges: {},
    basemap: { type: "none" },
    filters: {},
    language: "en",
    layers: [],
    layouts: {},
    loaded: false,
    mapCoordinates: {},
    measureDist: 0,
    measuring: false,
    paints: {},
    print: false,
    printOutline: null,
    restrictedView: defaultRestrictedView,
    sources: [],
    zoom: 3,
    zoomRanges: {},
    isAisDefaultVisible: false,
}

const mapSlice = createSlice({
    extraReducers: builder =>
        builder
            .addCase(mapZoomEnd, (state, { payload: newZoom }) => {
                state.zoom = newZoom
            })
            .addCase(toggleAppLayer, ({ layers, layouts }, { payload: { resourceId, visible } }) => {
                layers.forEach(layer => {
                    if (layer.resourceId === resourceId) {
                        const visibilityProperty = layouts[layer.layerId].properties.find(
                            prop => prop.name === "visibility",
                        )
                        if (visibilityProperty) {
                            visibilityProperty.value = visible ? "visible" : "none"
                        }
                    }
                })
            })
            .addCase(toggleGroupLayers, ({ layers, layouts }, { payload: { groupLayersMap, newVisibility } }) => {
                const affectedLayersArray = layers.filter(lay => groupLayersMap[lay.resourceId!])
                affectedLayersArray.forEach(({ layerId }) => {
                    const visibilityProperty = layouts[layerId].properties.find(prop => prop.name === "visibility")
                    if (visibilityProperty) visibilityProperty.value = newVisibility ? "visible" : "none"
                })
            })
            .addCase(
                getSubscriptions.fulfilled,
                ({ aisLayouts, aisPaints, aisZoomRanges, isAisDefaultVisible }, { payload: subscriptions }) => {
                    subscriptions.forEach(sub => {
                        aisLayouts[sub.id] = mapSubToLayout(sub.id) as LayoutEntry
                        aisPaints[sub.id] = mapSubToPaint(sub.id) as PaintEntry
                        aisZoomRanges[sub.id] = mapSubToZoomRange(sub.id)
                        Object.values(aisLayouts).forEach(layout => {
                            const visibilityProperty = layout.properties.find(prop => prop.name === "visibility")
                            if (visibilityProperty) visibilityProperty.value = isAisDefaultVisible ? "visible" : "none"
                        })
                    })
                },
            )
            .addCase(setBoats, ({ aisLayers, aisSources }, { payload: { boats, subscriptionId } }) => {
                const source = mapSubToGeoJsonSource(subscriptionId, boats)
                const oldSourceIndex = aisSources.findIndex(s => s.id === source.id)
                if (oldSourceIndex !== -1) {
                    aisSources[oldSourceIndex] = source
                } else {
                    aisSources.push(source)
                }

                const existingLayerIndex = aisLayers.findIndex(l => l.layerId === subscriptionId)

                // Add layer only if it doesn't exist
                if (existingLayerIndex === -1) aisLayers.push(mapSubToLayer(subscriptionId))
            })
            .addCase(setSubVisibility, ({ aisLayouts }, { payload: { subscriptionId, isVisible } }) => {
                const visibilityProperty = aisLayouts[subscriptionId].properties.find(
                    prop => prop.name === "visibility",
                )
                if (visibilityProperty) visibilityProperty.value = isVisible ? "visible" : "none"
            })
            .addCase(clearMap.type, state => {
                state.layouts = {}
                state.paints = {}
                state.layers = []
                state.sources = []
            })
            .addCase(resetProjectData, () => initialState)
            .addCase(getApp.fulfilled, (state, { payload: { isAisDefaultVisible, ..._ } }) => {
                state.isAisDefaultVisible = isAisDefaultVisible
            }),
    initialState,
    name: "map",
    reducers: {
        addMapLayer: ({ layers }, { payload: { newLayer, targetResourceId } }) => {
            const destinationIndex = layers.findIndex(l => l.resourceId === targetResourceId)
            layers.splice(destinationIndex, 0, newLayer)
        },
        addMapLayout: ({ layouts }, { payload: { layerId, properties } }) => {
            layouts[layerId] = { layerId, properties }
        },
        addMapPaint: ({ paints }, { payload: { layerId, properties } }) => {
            paints[layerId] = { layerId, properties }
        },
        addMapZoomRange: ({ zoomRanges }, { payload: { layerId, maxZoom, minZoom } }) => {
            zoomRanges[layerId] = { layerId, maxZoom, minZoom }
        },
        addSources: ({ sources }, { payload: newSources }) => {
            newSources.forEach((newSource: Source) => {
                const existingSourceIndex = sources.findIndex(s => s.id === newSource.id)
                if (existingSourceIndex !== -1) {
                    sources[existingSourceIndex] = newSource
                } else {
                    sources.push(newSource)
                }
            })
        },
        clearAisMapState: state => {
            state.aisLayouts = {}
            state.aisLayers = []
            state.aisSources = []
            state.aisPaints = {}
        },
        hideAllLayers: ({ layouts }) => {
            Object.keys(layouts).forEach(layerId => {
                const visibilityProperty = layouts[layerId].properties.find(prop => prop.name === "visibility")
                if (visibilityProperty) {
                    visibilityProperty.value = "none"
                } else {
                    console.error("Couldn't find the visibility property for layer " + layerId)
                }
            })
        },
        initMapResources: (state, { payload: { layers, layoutsMap, paintsMap, zoomRangesMap } }) => {
            state.layers = layers
            state.paints = paintsMap
            state.layouts = layoutsMap
            state.zoomRanges = zoomRangesMap
        },
        initMapSettings: (state, { payload: { basemap, language } }) => {
            state.language = language
            state.basemap = basemap
        },
        mapClick: (state, { payload: clickPos }: PayloadAction<ClickPos>) => {
            state.clickPos = clickPos
        },
        moveLayer: (state, { payload: { beforeLayerId, layerId } }) => {
            const layers = state.layers

            const movedLayerIndex = layers.findIndex(l => l.layerId === layerId)
            const destinationIndex = layers.findIndex(l => l.layerId === beforeLayerId)

            const layer = layers.splice(movedLayerIndex, 1)[0]
            layers.splice(destinationIndex, 0, layer)
        },
        removeMapLayer: ({ layers, layouts, paints, zoomRanges }, { payload: layerId }) => {
            const layerIndex = layers.findIndex(l => l.layerId === layerId)
            layers.splice(layerIndex, 1)
            delete paints[layerId]
            delete layouts[layerId]
            delete zoomRanges[layerId]
        },
        toggleMeasure: (state, { payload: measuring }) => {
            state.measuring = measuring
            state.measureDist = 0
        },
        updateMapLayer: ({ layers, layouts, paints }, { payload: updatedLayer }) => {
            const layer = layers.find(l => l.layerId === updatedLayer.layerId)
            if (layer) {
                Object.assign(layer, updatedLayer)
            } else {
                console.error("Layer " + updatedLayer.layerId + " could not be found")
            }
            delete paints[updatedLayer.layerId]
            delete layouts[updatedLayer.layerId]
        },
        updateMapLayout: ({ layouts }, { payload: { layerId, properties } }) => {
            layouts[layerId] = { layerId, properties }
        },
        updateMapPaint: ({ paints }, { payload: { layerId, properties } }) => {
            paints[layerId] = { layerId, properties }
        },
        updateMapProperty: ({ layouts, paints }, { payload: { layerId, property } }) => {
            const propertyDict = property.type === "layout" ? layouts : paints
            const propIndex = propertyDict[layerId].properties.findIndex(p => p.name === property.name)
            if (propIndex !== -1) {
                propertyDict[layerId].properties[propIndex] = property
            } else {
                console.error("Property index could not be found")
            }
        },
        updateMapZoomRange: ({ zoomRanges }, { payload: { layerId, maxZoom, minZoom } }) => {
            zoomRanges[layerId] = { layerId, maxZoom, minZoom }
        },
        setBasemap: (state, { payload: newBasemap }) => {
            state.basemap = newBasemap
        },
        setFilters: (state, { payload }) => {
            state.filters[payload.layerId] = payload.filters
        },
        setLanguage: (state, { payload: newLanguage }) => {
            state.language = newLanguage
        },
        setMapLayerVisibility: ({ layouts }, { payload: { layerId, newVisibility } }) => {
            layouts[layerId].properties.forEach(property => {
                if (property.name === "visibility") {
                    property.value = newVisibility
                }
            })
        },
        setMapLoaded: (state, { payload: loadedStatus }) => {
            state.loaded = loadedStatus
        },
        setMeasureDist: (state, { payload: newDist }) => {
            state.measureDist = newDist
        },
        setRestrictedView: (state, { payload }) => {
            state.restrictedView = payload
        },
    },
})

export const {
    addMapLayer,
    addMapLayout,
    addMapPaint,
    addMapZoomRange,
    addSources,
    clearAisMapState,
    hideAllLayers,
    initMapResources,
    initMapSettings,
    mapClick,
    moveLayer,
    removeMapLayer,
    toggleMeasure,
    updateMapLayer,
    updateMapLayout,
    updateMapPaint,
    updateMapProperty,
    updateMapZoomRange,
    setBasemap,
    setFilters,
    setLanguage,
    setMapLayerVisibility,
    setMapLoaded,
    setMeasureDist,
    setRestrictedView,
} = mapSlice.actions

export default mapSlice.reducer
