import React, { useCallback, useEffect, useRef, useState } from "react"
import { IHubProtocol } from "@microsoft/signalr"
import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack"
import { getSubscriptions } from "actions/ais"
import useAuthentication from "app/hooks/useAuthentication"
import useGetIsPublicApp from "app/hooks/useGetIsPublicApp"
import LoadingPlaceholder from "components/common/LoadingPlaceholder/LoadingPlaceholder"
import { useFetchIsAISPublicQuery } from "features/ais/api"
import { AisResponse } from "features/ais/models/AisResponse"
import { getClientId, getProjectId } from "features/core/selectors"
import { getTools } from "features/mapTools/selectors"
import { setBoats } from "reducers/ais"
import { clearAisMapState } from "reducers/map"
import { getFeatureFlags } from "selectors/featureFlagsSelectors"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import { useAppSelector } from "store/hooks/useAppSelector"
import useSignalR from "utils/customHooks/useSignalR"

const AisProvider: React.FCWC = ({ children }) => {
    const isPublicApp = useGetIsPublicApp()

    const [aisSubscriptionsInitialized, setAisSubscriptionsInitialized] = useState(false)

    // Fetch the AIS public flag
    const { data: { isPublic: isAisPublicQueryResponse } = { isPublic: false }, isLoading: isLoadingForAisPublicFlag } =
        useFetchIsAISPublicQuery(undefined, { skip: !isPublicApp })

    const tools = useAppSelector(getTools)

    const hasAisTool = Boolean(tools.find(({ name }) => name === "ais"))

    // Get the feature flags for non-public app
    const featureFlags = useAppSelector(getFeatureFlags)

    const isAisEnabled = (isPublicApp ? isAisPublicQueryResponse : featureFlags.AIS) && hasAisTool

    const isAisFlagLoading = isPublicApp ? isLoadingForAisPublicFlag : false

    const isAisLoading = isAisFlagLoading || (isAisEnabled && !aisSubscriptionsInitialized)

    const protocol = useRef(new MessagePackHubProtocol())

    const clientId = useAppSelector(getClientId)

    const projectId = useAppSelector(getProjectId)

    const dispatch = useAppDispatch()

    const { accessToken } = useAuthentication()

    const tokenFactory = useCallback(() => accessToken ?? "", [accessToken])

    const path = `ais/aishub?ClientId=${clientId}&ProjectId=${projectId}`

    const { addEvent, removeEvent, isConnectionStarted } = useSignalR({
        hubProtocol: protocol.current as IHubProtocol,
        path,
        skip: !isAisEnabled,
        tokenFactory: isPublicApp ? undefined : tokenFactory,
    })

    useEffect(() => {
        if (!isAisEnabled) dispatch(clearAisMapState())
    }, [isAisEnabled, dispatch])

    // Fetch subscriptions
    useEffect(() => {
        // If AIS is not enabled, we don't need to fetch subscriptions
        if (!isAisEnabled || isAisFlagLoading) return

        // If AIS is enabled, we need to fetch subscriptions
        dispatch(getSubscriptions()).then(() => setAisSubscriptionsInitialized(true))
    }, [isAisEnabled, isAisFlagLoading])

    // Add event listener for AIS response
    useEffect(() => {
        // If AIS is not enabled, connection is not started or subscriptions are not initialized, we don't need to add event listener
        if (!isAisEnabled || !isConnectionStarted || !aisSubscriptionsInitialized) return

        addEvent("aisResponse", (data: AisResponse) => {
            // Since union types don't get deserialized, we get an array instead of an object
            // index 0 is the union type, index 1 is the data

            dispatch(setBoats(data))
        })

        return () => {
            removeEvent("aisResponse")
        }
    }, [isAisEnabled, isConnectionStarted, aisSubscriptionsInitialized, dispatch, accessToken])

    return (
        <LoadingPlaceholder loading={isAisLoading} message="Getting ais data">
            {children}
        </LoadingPlaceholder>
    )
}

export default AisProvider
