import { useEffect, useState } from "react"
import { HubConnection, HubConnectionBuilder, IHubProtocol, LogLevel } from "@microsoft/signalr"
import config from "config"

type EventType = {
    methodName: string
    handler: (...args: any[]) => void
}

type ReturnType = {
    addEvent: (methodName: string, handler: (...args: any[]) => void) => void
    connectionId: string | null
    removeEvent: (methodName: string) => void
    isConnectionStarted: boolean
}

type UseSignalR = {
    hubProtocol?: IHubProtocol
    path: string
    skip?: boolean
    tokenFactory?: () => string | Promise<string>
}

const logLevel = import.meta.env.PROD ? LogLevel.None : LogLevel.Information

const initHubConnection = (path: string, tokenFactory?: () => string | Promise<string>, protocol?: IHubProtocol) => {
    let connectionBuilder = new HubConnectionBuilder().configureLogging(logLevel).withAutomaticReconnect()

    if (tokenFactory) {
        connectionBuilder = connectionBuilder.withUrl(config.apiUrl + path, {
            accessTokenFactory: tokenFactory,
        })
    } else {
        connectionBuilder = connectionBuilder.withUrl(config.apiUrl + path)
    }

    if (protocol) {
        connectionBuilder = connectionBuilder.withHubProtocol(protocol)
    }

    return connectionBuilder.build()
}

const useSignalR = ({ hubProtocol, path, skip, tokenFactory }: UseSignalR): ReturnType => {
    const [connection, setConnection] = useState<HubConnection | null>(() =>
        skip ? null : initHubConnection(path, tokenFactory, hubProtocol),
    )
    const [events, setEvents] = useState<EventType[]>([])
    const [isConnectionStarted, setIsConnectionStarted] = useState(false)

    useEffect(() => {
        const validConnectionPath = connection?.baseUrl === config.apiUrl + path

        if (skip) {
            if (validConnectionPath) {
                connection.stop()
                setConnection(null)
                setIsConnectionStarted(false)
            }
            return
        }

        if (!validConnectionPath) {
            setConnection(initHubConnection(path, tokenFactory, hubProtocol))
        }
    }, [path, tokenFactory, skip])

    useEffect(() => {
        if (!connection || skip) return

        connection
            .start()
            .then(() => {
                console.log("websocket connect successfully to " + path)
                setIsConnectionStarted(true)
            })
            .catch(() => {
                console.error("Can't connect websocket")
                setIsConnectionStarted(false)
            })

        return () => {
            connection.stop()
            setIsConnectionStarted(false)
        }
    }, [connection, skip])

    useEffect(() => {
        if (!connection) return
        events.forEach(e => connection.on(e.methodName, e.handler))
    }, [connection])

    const addEvent = (methodName: string, handler: (...args: any[]) => void) => {
        if (!connection) return
        if (events.some(e => e.methodName === methodName)) return
        setEvents([...events, { methodName, handler }])
        connection.on(methodName, handler)
    }

    const removeEvent = (methodName: string) => {
        if (!connection) return
        setEvents(events.filter(e => e.methodName !== methodName))
        connection.off(methodName)
    }

    return {
        addEvent,
        connectionId: connection?.connectionId || null,
        removeEvent,
        isConnectionStarted,
    }
}

export default useSignalR
