import { useEffect, useState } from "react"
import {
    AccountInfo,
    BrowserAuthError,
    ClientAuthError,
    ClientAuthErrorMessage,
    InteractionRequiredAuthError,
} from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import jwt_decode from "jwt-decode"

dayjs.extend(utc)

type JWTProperties = {
    exp: number
}

type UseAccessTokenProps = {
    scopes: string[]
    isPublic?: boolean
}

const getExpirationUtcTimestampFromToken = (token: string) => {
    const jwtProperties: JWTProperties = jwt_decode(token)

    // The expiration date is in seconds, we need to convert it to milliseconds
    // and subtract 5 minutes to account for clock skew
    return dayjs((jwtProperties.exp - 5 * 60) * 1000)
        .utc()
        .toDate()
        .getTime()
}

const getCurrentUtcTimestamp = () => {
    return dayjs.utc().toDate().getTime()
}

const getIsTokenExpired = (token: string) => {
    return getExpirationUtcTimestampFromToken(token) < getCurrentUtcTimestamp()
}

const useAccessToken = ({ scopes, isPublic }: UseAccessTokenProps) => {
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined)

    const [error, setError] = useState<unknown | null>(null)

    const [isAccessTokenLoading, setIsAccessTokenLoading] = useState(true)

    const { inProgress, instance: msalInstance } = useMsal()

    const acquireToken = async (props?: { forceRefresh?: boolean }) => {
        try {
            const activeAccount = msalInstance.getActiveAccount()

            if (!activeAccount) {
                await msalInstance.acquireTokenRedirect({ scopes })
                return null
            }

            return await msalInstance.acquireTokenSilent({
                account: activeAccount,
                forceRefresh: props?.forceRefresh,
                scopes,
            })
        } catch (error: unknown) {
            setError(error)
            throw error
        }
    }

    const getNewAccessToken = async (props?: { forceRefresh?: boolean }) => {
        const response = await acquireToken({ forceRefresh: props?.forceRefresh })

        if (response !== null) {
            setAccessToken(response.accessToken)
            return
        }

        setAccessToken(undefined)
    }

    // If there is an error, we try to get a new access token with redirect
    useEffect(() => {
        if (inProgress !== "none" || !error) return

        console.error("Authentication error: ", error)
        if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
            msalInstance.acquireTokenRedirect({
                account: msalInstance.getActiveAccount() as AccountInfo,
                scopes,
            })
        }
        if (
            error instanceof ClientAuthError &&
            error.errorCode === ClientAuthErrorMessage.multipleMatchingTokens.code
        ) {
            localStorage.clear()
            msalInstance.acquireTokenRedirect({
                account: msalInstance.getActiveAccount() as AccountInfo,
                scopes,
            })
        }

        return () => {
            setError(null)
        }
    }, [error, inProgress])

    useEffect(() => {
        // If the user is in the public view, we don't need to get the access token
        if (isPublic) {
            setIsAccessTokenLoading(false)
            setAccessToken(undefined)
            return
        }

        if (inProgress !== "none") return

        // If the access token is not set we get a new access token
        if (!accessToken) {
            getNewAccessToken().then(() => setIsAccessTokenLoading(false))
            return
        }

        // If the access token is expired, we get a new access token with forceRefresh
        if (getIsTokenExpired(accessToken)) {
            getNewAccessToken({ forceRefresh: true }).then(() => setIsAccessTokenLoading(false))
            return
        }

        // msDifference is the time in milliseconds before the token expires
        const expiresInMs = getExpirationUtcTimestampFromToken(accessToken) - getCurrentUtcTimestamp()

        if (expiresInMs > 0) {
            // We set a timeout to get a new access token before the current one expires
            const timeout = setTimeout(() => getNewAccessToken({ forceRefresh: true }), expiresInMs)
            return () => clearTimeout(timeout) // Cleanup on unmount or token change
        }
    }, [accessToken, msalInstance, isPublic, inProgress, getNewAccessToken]) // Triggers when the token is updated

    return { accessToken, isAccessTokenLoading }
}

export default useAccessToken
